/* eslint-disable camelcase */
import { AxiosRequestConfig } from "axios";
import { TEndpoint } from "@@types/api.core";
import { mergeRight } from "ramda";
import { FILTER_API } from "@constants";
import { TWpGlobal } from "#types/wordPress";
import Api from "@core/api.core";
import getConfig from "next/config";

const { wpApiHost } = getConfig().publicRuntimeConfig;

export class CptRepository extends Api {
  protected readonly endpoints: TEndpoint = {
    posts: {
      allCptTestimony: `/wp/v2/quote_post_type${FILTER_API}`,
      allCptJobFairs: `/wp/v2/fairs_post_type${FILTER_API}`,
      sliderByCategory: `/wp/v2/slider_post_type?categories={id}`
    }
  };

  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(mergeRight({ baseURL: `${wpApiHost}/wp-json` }, baseOptions));
  }

  async getAllCptTestimony(): Promise<TWpGlobal[]> {
    return this.get<TWpGlobal[]>(this.endpoints.posts.allCptTestimony).then((response) => response.data);
  }

  async getAllCptJobFairs(): Promise<TWpGlobal[]> {
    return this.get<TWpGlobal[]>(this.endpoints.posts.allCptJobFairs).then((response) => response.data);
  }

  async getSliderByCategory(id: number): Promise<TWpGlobal[]> {
    const endpoint = this.endpoints.posts.sliderByCategory.replace("{id}", id.toString());
    return this.get<TWpGlobal[]>(endpoint).then((response) => response.data);
  }
}

export default new CptRepository();
