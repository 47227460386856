/* eslint-disable new-cap */
import { BusinessType } from "@@types/gtm";
import withAuthHoc from "@components/auth/listener/hoc";
import Layout from "@components/layouts/component";
import { AuthContext } from "@context";
import useDeviceDetected from "@hooks/useDeviceDetected";
import { ROUTES_B2B } from "@schemas/routes";
import "@styles/antd.less";
import "@styles/globals.scss";
import "@styles/line-awesome.css";
import "@styles/main.scss";
import "magneto365.ui/dist/cjs/css/magneto.ui.lib.min.css";
import { appWithTranslation } from "next-i18next";
import type { AppProps } from "next/app";
import { useContext } from "react";

function MyApp({ Component, pageProps, router }: AppProps) {
  const { isAuthenticated } = useContext(AuthContext);
  const routesB2b = ROUTES_B2B;
  const device = useDeviceDetected();

  // useVisibilityHeaderSearch("/");

  const getLayoutType = () => {
    const path = router.pathname;

    if (path.startsWith(routesB2b.events) || path.startsWith(routesB2b.landingsFullWidth)) {
      return "empty";
    } else if (path.startsWith(routesB2b.business)) {
      return "pymes";
    } else if (path.startsWith(routesB2b.solutions)) {
      return "b2b";
    }
    return "b2c";
  };

  const getLayout = (type: BusinessType) => {
    return (
      <Layout type={type} device={device} isAuthenticated={isAuthenticated}>
        <Component {...pageProps} />
      </Layout>
    );
  };

  return getLayout(getLayoutType());
}

export default withAuthHoc(appWithTranslation(MyApp));
